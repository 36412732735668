
const Ragdolls = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh'
        }}
      >
        
        
      </div>
    );
  };

export default Ragdolls
