import image1 from "../photos/Como/Como9.jpg";
import image2 from "../photos/Como/Como26.jpg";
import image3 from "../photos/Como/Como27.jpg";
import image4 from "../photos/Como/Como30.jpg";
import image5 from "../photos/Como/Como31.jpg";
import image6 from "../photos/Como/Como38.jpg";
import image7 from "../photos/Como/Como56.jpg";
import image8 from "../photos/Como/Como58.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "./galleryStyles.css";

// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

export default function ComoGallery() {
  
  return (
    <>
    <script>defer </script>

    <Swiper 
    slidesPerView={'3'}
    spaceBetween={15}
    pagination={{"clickable": true}}
    style={{backgroundColor: 'white'}}    

    className="mySwiper">
  <SwiperSlide><img src={image1} className="sliderimg" alt="1" /></SwiperSlide>
  <SwiperSlide><img src={image2} className="sliderimg" alt="2" /></SwiperSlide>
  <SwiperSlide><img src={image3} className="sliderimg" alt="3" /></SwiperSlide>
  <SwiperSlide><img src={image4} className="sliderimg" alt="4" /></SwiperSlide>
  <SwiperSlide><img src={image5} className="sliderimg" alt="5" /></SwiperSlide>
  <SwiperSlide><img src={image6} className="sliderimg" alt="6" /></SwiperSlide>  
  <SwiperSlide><img src={image7} className="sliderimg" alt="7" /></SwiperSlide>  
  <SwiperSlide><img src={image8} className="sliderimg" alt="8" /></SwiperSlide> 
  </Swiper>
    </>
  )
}