import './aboutUs.css';
import LewisCard from '../components/LewisCard';
import WendyCard from '../components/WendyCard';
import { Helmet } from 'react-helmet-async';


const AboutUs = () => {
    return (
      <div>
        <Helmet>
          <title>O nás</title>
          <meta 
          name="description"
          content="Informace o nás a naší stanici. Proč a jak chováme naše Ragdolly. Naším cílem je vychovat a poskytnout kvalitní kočičku se kterou si budete rozumět."/>
          <link rel="canonical" href="/about-us" />    
        </Helmet>
            
      <div className="grid-container">             
      <div className="columns">
        <div>          
          <p>Naší prioritou je vychovat koťátka, která budou milá, šťastná, zdravá, přítulná a socializovaná. Koťata odchází z naší chovné stanice s průkazem původu, pet passem, očkovaná, odčervená, čipovaná. Koťata mají čisté a kvalitní linie po svých rodičích, kteří pocházejí z uznávaných zahraničních chovných stanic (HU* Aquamarinedolls, Lodgar Grand*CZ).</p> <br/> <p> 
Záleží nám, v první řadě, na zdraví a samozřejmě na kvalitě našeho chovu. Proto se snažíme poskytnout všem našim čtyřnohým mazlíčkům dostatek prostoru v našem rodinném domě spojeného s vnějším zabezpečeným výběhem.
 Samozřejmostí jsou kvalitní krmiva, která dostávají již od útlého věku (Feringa, Royal Canin, Cosma, Animonda, Applaws a suché krmivo Farmina N&D), vitamínové přípravky, probiotika a prebiotika. Všechny naše kočky mají kvalitní a pravidelnou veterinární péči. Samozřejmě mají nekonečně mnoho naší lásky a času. Jsou to členové naší rodiny. 
</p> <br/> <p>Koťata, která od nás odcházejí do nových domovů, jsou plně socializovaná, naučená na kočičí toaletu a zvyklá na život s lidmi. Jsou hravá, přítulná a milující. Požadují spoustu lásky, něhy a společně stráveného času. Ragdoll, který nemá lidskou pozornost – trpí. A proto si dobře promyslete, zda mu ji jste schopni poskytnout.  Pokud ne, nepořizujte si ragdolla. Taková želvička nebo rybky v akváriu dají daleko méně práce a nejsou tak finančně nákladné.
V kupní smlouvě garantujeme kvalitu a čistotu plemene a zdraví našich koťat. Všechna naše chovná zvířata jsou testována na genetická onemocnění HCM, PKD, také na FeLV a FIV.
</p>           
        </div>        
      </div>  

      <div className="columns">
        <div>
          <h2>Naším cílem je pro vás vychovat mazlivé a hravé ragdolly.</h2>
          <p>S velkou láskou a péčí chováme čistokrevné  ragdolly s průkazem původu (PP), kteří mají velmi kvalitní, importované rodiče.</p> <br/>
          <p>Milujeme je pro jejich přátelskou a klidnou povahu, krásné azurově modré oči  a pro jejich mohutnou stavbu těla. </p>
        </div>
        <div>
          <h2>Certifikace kvality.</h2>
          <p>Všechny naše kočky jsou řádně očkované a čipované. Naše chovatelská stanice je členem mezinárodní chovatelské organizace FIFE. To znamená jistotu čistého a zdravého plemene.</p>
        </div>
      </div>         


      <div className="spacer-line">
        <p>Naše chovné kočky</p>
      </div>

      <div className="columns-2-1">
         <LewisCard></LewisCard>   
         <WendyCard></WendyCard>   
      
      </div>

    </div>
    </div>
  );
};

export default AboutUs
