import { useState } from "react";
 import { TextLoop } from "react-text-loop-next";
import '../../pages/index.css'; 

const MaskedFunction = () => (
  <div className="homeText">
    Chceme, aby vám <br /> naše kočky daly <br />
      <TextLoop className="homeTextLooped" mask={true}>
        <span>Štěstí</span>
        <span>Pozornost</span>        
        <span>Radost</span>
        <span>Mazlení</span>
      </TextLoop>{" "}      
    </div>
);


enum TypeOfLoop {
  MaskedFunction
}

const LoopedText = () => {
  const [activeSection] = useState<TypeOfLoop>(TypeOfLoop.MaskedFunction);

  const mapSectionToComponent = {
    [TypeOfLoop.MaskedFunction]: MaskedFunction
  };

  const ExampleSection = mapSectionToComponent[activeSection];
  return (
    <div>
      <ExampleSection />
    </div>
  );
};

export default LoopedText;
