import image1 from "../photos/General/gen2.jpg";
import image2 from "../photos/General/gen3.jpg";
import image3 from "../photos/General/gen4.jpg";
import image4 from "../photos/General/gen5.jpg";
import image5 from "../photos/General/gen6.jpg";
import image6 from "../photos/General/gen7.jpg";
import image7 from "../photos/General/gen8.jpg";
import image8 from "../photos/General/gen9.jpg";
import image9 from "../photos/General/gen10.jpg";
import image10 from "../photos/General/gen11.jpg";
import image11 from "../photos/General/gen12.jpg";
import image12 from "../photos/General/gen13.jpg";
import image13 from "../photos/General/gen14.jpg";


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "./galleryStyles.css";

// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

export default function VrhCGeneralGallery() {
  
  return (
    <>
    <script>defer</script>

    <Swiper 
    slidesPerView={'3'}
    spaceBetween={15}
    style={{backgroundColor: 'white'}}
    pagination={{"clickable": true}}

    className="mySwiper">
  <SwiperSlide><img src={image1} className="sliderimg" alt="1" /></SwiperSlide>
  <SwiperSlide><img src={image2} className="sliderimg" alt="2" /></SwiperSlide>
  <SwiperSlide><img src={image3} className="sliderimg" alt="3" /></SwiperSlide>
  <SwiperSlide><img src={image4} className="sliderimg" alt="4" /></SwiperSlide>
  <SwiperSlide><img src={image5} className="sliderimg" alt="5" /></SwiperSlide>
  <SwiperSlide><img src={image6} className="sliderimg" alt="6" /></SwiperSlide>  
  <SwiperSlide><img src={image7} className="sliderimg" alt="7" /></SwiperSlide>  
  <SwiperSlide><img src={image8} className="sliderimg" alt="8" /></SwiperSlide>  
  <SwiperSlide><img src={image9} className="sliderimg" alt="9" /></SwiperSlide>  
  <SwiperSlide><img src={image10} className="sliderimg" alt="10" /></SwiperSlide>  
  <SwiperSlide><img src={image11} className="sliderimg" alt="11" /></SwiperSlide>  
  <SwiperSlide><img src={image12} className="sliderimg" alt="12" /></SwiperSlide>  
  <SwiperSlide><img src={image13} className="sliderimg" alt="13" /></SwiperSlide>  
  </Swiper>
    </>
  )
}