import './availableKittens.css'
import KittensAccordion from '../components/Accordion/accordion';
import { Helmet } from 'react-helmet-async';

const AvailableKittens = () => {
  return (
    <div>
      <Helmet>
          <title>Naše koťátka</title>
          <meta 
          name="description"
          content="Všechny naše kočičky možné ke koupi. Je možné vidět i kočičky z předchozích vrhů včetně popisků a obrázků. "
          />
        </Helmet>           
      
      <div className="accordionContainer">
        <KittensAccordion></KittensAccordion>
      </div>
    </div>
    
  );
};

export default AvailableKittens
