import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion } from "react-bootstrap";
import { BiMaleSign } from "react-icons/bi";
import { BiFemaleSign } from "react-icons/bi";
import BelmondoGallery from "../imageGallery/belmondoGallery";
import BettyGallery from "../imageGallery/bettyGallery";
import CalistaGallery from "../imageGallery/calistaGallery";
import CalvinGallery from "../imageGallery/calvinGallery";
import CiceroGallery from "../imageGallery/ciceroGallery";
import ClairGallery from "../imageGallery/clairGallery";
import ComoGallery from "../imageGallery/comoGallery";
import VrhCGeneralGallery from "../imageGallery/vrhCGeneralGallery";
import VrhDGeneralGallery from "../imageGallery/vrhDGeneralGallery";
import DemiGallery from "../imageGallery/demiGallery";
import DebbieGallery from "../imageGallery/debbieGallery";
import DaisyGallery from "../imageGallery/daisyGallery";
import DymoGallery from "../imageGallery/dymoGallery";
import DafneGallery from "../imageGallery/dafneGallery";
import DustinGallery from "../imageGallery/dustinGallery";
import DracoGallery from "../imageGallery/dracoGallery";
import DanteGallery from "../imageGallery/danteGallery";

const galerryTextStyle = {    
    textAlign: "center",
    paddingBottom: "0px",
    marginBottom: "10px",   
}

const galerryTextStyleRed = {    
    textAlign: "center",
    paddingBottom: "0px",
    marginBottom: "10px",   
    color: "red",
    fontWeight: "bold",
}

const descriptionTextStyle = {    
    textAlign: "center",
    paddingBottom: "0px",
    marginTop: "15px",
}

const HeaderTextStyle = {  
    textAlign: "center",  
    fontSize: "44px",
    fontWeight: "bold",
}

const HeaderBirthTextStyle = {  
    // marginTop: "0px",
    // paddingTop:"0px",
    marginBottom: "14px",
    textAlign: "center",  
}

const notificationBox = {    
    textAlign: "center",
    fontWeight: "bold",
    background: "#256ce1",
    color: "white",
    paddingTop:"1rem",
    paddingBottom:"1rem",
    marginLeft:"3rem",
    marginRight:"3rem",
    border: '2px solid red'

    // maxWidth: "40rem",
    // display:"flex",
    // textAlign: "center",
    // alignSelf:"center",
    // alignContent: "center",
    // alignItems:"center"
}


export default function KittensAccordion() {
    return (
        <div className="Accordions">            
            <Accordion defaultActiveKey={["0","1"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Dostupné ke koupi</Accordion.Header> 

                    <Accordion.Body>                 
                    {/* <p style= {HeaderBirthTextStyle}>Všechna koťata mají nové domovy</p> */}
                     <div>
                        <div>
                            {/* <p>Všechna koťata mají nové domovy </p> */}

                            <Accordion.Body>
                    <p style= {HeaderTextStyle}>VRH D</p>
                    <p style= {HeaderBirthTextStyle}>narozeno 19.6.2023</p>                                  
                    
                    {/* <VrhDGeneralGallery></VrhDGeneralGallery>
                    <p style={descriptionTextStyle}>
                                    <br/>
                    </p>                     */}
                    </Accordion.Body> 

                    <Accordion.Body>
                    <p style={galerryTextStyle}>
                    <BiMaleSign size='14'/> Draco Bellacola, CZ
                    </p>
                    {/* <p style={galerryTextStyleRed}>! REZERVOVÁNO !</p> */}
                    <DracoGallery></DracoGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kocourek <br/>
                                    bicolor 
                                    <br/>
                    </p>

                    </Accordion.Body>
                        </div>
                    </div>
                    </Accordion.Body> 
                    
                </Accordion.Item>
                
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Naše předchozí vrhy</Accordion.Header>
                     

                    <Accordion.Body>
                    <p style= {HeaderTextStyle}>VRH D</p>
                    <p style= {HeaderBirthTextStyle}>narozeno 19.6.2023</p>                                  
                    
                    <VrhDGeneralGallery></VrhDGeneralGallery>
                    <p style={descriptionTextStyle}>
                                    <br/>
                    </p>                    
                    </Accordion.Body> 


                    <Accordion.Body style={{backgroundColor: "lightblue"}}>
                    <p style={galerryTextStyle}>
                    <BiFemaleSign size='14'/> Daisy Bellacola, CZ
                    </p>
                    <p style={galerryTextStyleRed}>! Již v novém domově !</p>
                    <DaisyGallery></DaisyGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kočička <br/>
                                    bicolor 
                                    <br/>
                    </p>

                    </Accordion.Body>
                    
                                       
                    <Accordion.Body style={{backgroundColor: "lightblue"}}>
                    <p style={galerryTextStyle}>
                    <BiMaleSign size='14'/> Dymo Bellacola, CZ
                    </p>
                    <p style={galerryTextStyleRed}>! Již v novém domově !</p>
                    <DymoGallery></DymoGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kocourek <br/>
                                    seal mitted, RAG n 04
                                    <br/>
                    </p>

                    </Accordion.Body>

                    <Accordion.Body style={{backgroundColor: "lightblue"}}>
                    <p style={galerryTextStyle}>
                    <BiFemaleSign size='14'/> Demi Bellacola, CZ
                    </p>
                    <p style={galerryTextStyleRed}>! Již v novém domově !</p>
                    <DemiGallery></DemiGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kočička <br/>
                                    colorpoint, RAG n
                                    <br/>
                    </p>

                    </Accordion.Body>

                    <Accordion.Body style={{backgroundColor: "lightblue"}}>
                    <p style={galerryTextStyle}>
                    <BiFemaleSign size='14'/> Debbie Bellacola, CZ
                    </p>
                    <p style={galerryTextStyleRed}>! Již v novém domově !</p>
                    <DebbieGallery></DebbieGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kočička <br/>
                                    seal mitted, RAG n 04
                                    <br/>
                    </p>

                    </Accordion.Body>

                    <Accordion.Body style={{backgroundColor: "lightblue"}}>
                    <p style={galerryTextStyle}>
                    <BiFemaleSign size='14'/> Dafne Bellacola, CZ
                    </p>
                    <p style={galerryTextStyleRed}>! Již v novém domově !</p>
                    <DafneGallery></DafneGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kočička <br/>
                                    modrý colorpoint, RAG a
                                    <br/>
                    </p>

                    </Accordion.Body>                    

                    <Accordion.Body style={{backgroundColor: "lightblue"}}>
                    <p style={galerryTextStyle}>
                    <BiMaleSign size='14'/> Dustin Bellacola, CZ
                    </p>
                    <p style={galerryTextStyleRed}>! Již v novém domově !</p>
                    <DustinGallery></DustinGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kocourek <br/>
                                    bicolor
                                    <br/>
                    </p>

                    </Accordion.Body>
                    
                    <Accordion.Body style={{backgroundColor: "lightblue"}}>
                    <p style={galerryTextStyle}>
                    <BiMaleSign size='14'/> Dante Bellacola, CZ
                    </p>
                    <p style={galerryTextStyleRed}>! Již v novém domově !</p>
                    <DanteGallery></DanteGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kocourek <br/>
                                    seal mitted, RAG n 04
                                    <br/>
                    </p>

                    </Accordion.Body>

                    <Accordion.Body>
                    <p style= {HeaderTextStyle}>VRH C</p>
                    <p style= {HeaderBirthTextStyle}>narozeno 14.5.2022</p>                                  
                    
                    <VrhCGeneralGallery></VrhCGeneralGallery>
                    <p style={descriptionTextStyle}>
                                    <br/>
                    </p>                    
                    </Accordion.Body> 

                    <Accordion.Body>
                    <p style={galerryTextStyle}>
                    <BiFemaleSign size='14'/> Calista Bellacola, CZ
                    </p>
                    <CalistaGallery></CalistaGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kočička <br/>
                                    seal mitted, RAG n 04
                                    <br/>
                    </p>

                    </Accordion.Body>

                    <Accordion.Body>
                    <p style={galerryTextStyle}>
                    <BiMaleSign size='14'/> Como Bellacola, CZ
                    </p>
                    <ComoGallery></ComoGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kocourek <br/>
                                    seal mitted, RAG n 04
                                    <br/>
                                    <br/>
                    </p>
                    </Accordion.Body>  
                    
                    <Accordion.Body>
                    <p style={galerryTextStyle}>
                    <BiMaleSign size='14'/> Calvin Bellacola, CZ
                    </p>
                    <CalvinGallery></CalvinGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kocourek <br/>
                                    seal mitted, RAG n 04 
                                    <br/>
                                    <br/>
                    </p>
                    </Accordion.Body>

                    <Accordion.Body>
                    <p style={galerryTextStyle}>
                    <BiFemaleSign size='14'/> Clair Bellacola, CZ 
                    </p>
                    <ClairGallery></ClairGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kočička <br/>
                                    colorpoint, RAG n 
                                    <br/>
                                    <br/>
                    </p>
                    </Accordion.Body>

                    <Accordion.Body>
                    <p style={galerryTextStyle}>
                    <BiMaleSign size='14'/> Cicero Bellacola, CZ
                    </p>
                    <CiceroGallery></CiceroGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kocourek <br/>
                                    seal mitted, RAG n 04 
                                    <br/>
                                    <br/>
                    </p>
                    </Accordion.Body>

                    <Accordion.Body>     
                    <p style= {HeaderTextStyle}>VRH B</p>
                    <p style= {HeaderBirthTextStyle}>narozeno 13.9.2021</p>                    

                    <p style={galerryTextStyle}>
                    <BiMaleSign size='14'/> Belmondo Bellacola, CZ
                    </p>
                    <BelmondoGallery></BelmondoGallery>
                    <p style={descriptionTextStyle}>                   
                                    Kocourek <br/>
                                    colorpoint, RAG n 
                                    <br/>
                                    <br/>
                    </p>
                    </Accordion.Body> 

                    <Accordion.Body>
                    <p style={galerryTextStyle}>
                    <BiFemaleSign size='14'/> Betty Bellacola, CZ
                    </p>

                    <BettyGallery></BettyGallery>
                    <p style={descriptionTextStyle}>                    
                                    Kočička <br/>
                                    colorpoint, RAG n 
                                    <br/>
                                    <br/>
                    </p>
                    </Accordion.Body> 

                </Accordion.Item>
            </Accordion>        
        </div>
    );
}
