import './contact.css'
import emailjs from "emailjs-com";
import React from 'react';
import { BsFillTelephoneFill } from "react-icons/bs";
import { Helmet } from 'react-helmet-async';

import{ init } from 'emailjs-com';
init("b_XtQDuLDeeMwf96-");

const Contact = () => {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_dosw7mi', 'contact_form_template', e.target, 'b_XtQDuLDeeMwf96-')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  }


    return (
      <div>
        <Helmet>
          <title>Kontaktuje nás</title>
          <meta 
          name="description"
          content="Napište nebo zavolejte. Poptejte se, zda-li jsou kočičky stále k dispozici a promluvte si s námi ohledně možného osobního setkání!" />
          <link rel="canonical" href="/contact" />          
        </Helmet>      
      <div>        
        <div className="header-text">          
          <p>Zavolejte nám. S radostí vám odpovíme na vaše dotazy!</p>                  
        </div>                 
        <div className="header-call">          
          <p><BsFillTelephoneFill size="20"/>  tel:+420 608 333 056</p>          
        </div>  
          <div className="container">
            <form className="form" onSubmit={sendEmail}>
                    <div className="contact-box">                        
                        <div className="email-box">
                            <input type="email" className="form-control" placeholder="Email" name="email"/>
                        </div>
                        <div className="text-box">
                            <input type="text" className="form-control" placeholder="Subjekt" name="subject"/>
                        </div>
                        <div className="message-box">
                            <textarea className="form-control" id="" cols="30" rows="8" placeholder="Vaše zpráva" name="message"></textarea>
                        </div>
                        <div className="contact-button">
                            <button type="submit" className="contact-button-btn" value="Poslat">Poslat</button>
                        </div>
                    </div>
                </form>
            </div> 
            
                       
        </div> 
        </div>       

    );
  };

export default Contact
