import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages';
import AboutUs from './pages/aboutUs';
import Ragdolls from './pages/ragdolls';
import Contact from './pages/contact';
import AvailableKittens from './pages/availableKittens';

function App() {
  return (
    <> 
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/available-kittens" exact component={AvailableKittens} />
          <Route path="/ragdolls" exact component={Ragdolls} />
          <Route path="/contact" exact component={Contact} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
