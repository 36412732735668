import image1 from "../photos/Dustin/Dustin1.jpg";
import image2 from "../photos/Dustin/Dustin2.jpg";
import image3 from "../photos/Dustin/Dustin3.jpg";
import image4 from "../photos/Dustin/Dustin4.jpg";
import image5 from "../photos/Dustin/Dustin5.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "./galleryStyles.css";

// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

export default function DustinGallery() {
  
  return (
    <>
    <script>defer </script>

    <Swiper 
    slidesPerView={'3'}
    spaceBetween={15}
    pagination={{"clickable": true}}    
    style={{backgroundColor: 'white'}}    

    className="mySwiper">
  <SwiperSlide><img src={image1} className="sliderimg" alt="1" /></SwiperSlide>
  <SwiperSlide><img src={image2} className="sliderimg" alt="2" /></SwiperSlide>
  <SwiperSlide><img src={image3} className="sliderimg" alt="3" /></SwiperSlide>
  <SwiperSlide><img src={image4} className="sliderimg" alt="4" /></SwiperSlide>
  <SwiperSlide><img src={image5} className="sliderimg" alt="5" /></SwiperSlide>
  </Swiper>
    </>
  )
}