import './index.css'
//import catPlaceholder from '../catPlaceholder.png'
import catHome from '../catHome.png'
import LoopedText from '../components/loopedText/loopedText';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

        //TODO i18n next with button toggle translation https://www.youtube.com/watch?v=Od6VRVJH15Y&ab_channel=Codex
        //TODO check the router integration for i18n https://www.npmjs.com/package/react-router-i18n

        //TODO FAbars https://www.youtube.com/watch?v=VzWBLj_CfpE
        //TODO fix firefox font issue

        //DONE fix button and text placement on the home page
        //DONE Add Joy word text loop https://www.npmjs.com/package/react-text-loop

const Home = () => {
    return (
    <div>
      <Helmet>
          <title>Bellacola - Chovná Stanice</title>
          <meta 
          name="description"
          content="Jsme Belacolla - Chovná stanice Ragdoll koček. Naše Ragdoll koťata odchází z naší chovné stanice s průkazem původu, pet passem, očkovaná, odčervená, čipovaná."/>
          <link rel="canonical" href="/" />    
        </Helmet>

        <div >        
            <img className="homeCat" src={catHome} alt="cat" width="1352" height="768" title="Wendy, Ragdoll kočka"/>  
        </div>  
        <div className="gradient" />

      <div className="homeText"      
        style={{
          display: 'grid',
          justifyContent: 'center',
          height: '10.8rem',
        }}
        >                      
            <LoopedText />             

          <div>
            <Link to ="/available-kittens">
             <button className="ourKittensButton">Naše Koťátka</button>       
            </Link>
          </div> 
        </div>                  


        <div className="custom-shape-divider-bottom-1636412139">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
        </div>        
      </div>     
      
    );
  };

export default Home
