import { IoLanguageSharp } from 'react-icons/io5';

import {
    Nav,
    NavLink,    
    NavMenu,
    NavBtn,
    NavBtnLink
  } from './NavBarElements';

const NavBar = () => {
    return (
        <>
            <Nav>
                <NavLink to ="/">
                    <h1>{/*<GiCat size='18' />*/}  Bellacola </h1>
                </NavLink>
                <NavMenu> 
                    <NavLink to ="/about-us"> O nás </NavLink>  
                    <NavLink to ="/available-kittens"> Naše Koťátka </NavLink>                           
                    {/*<NavLink to ="/ragdolls"> Plemeno Ragdoll </NavLink>  */}                      
                    <NavLink to ="/contact"> Kontakt </NavLink>     

                             
                </NavMenu>                
                <NavBtn>
                    <NavBtnLink to="/"> <IoLanguageSharp size='20' /></NavBtnLink>
                </NavBtn>
            </Nav>
        </>
    )
}

export default NavBar
